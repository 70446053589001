export default [
    {
        path: "userManage",
        name: "userManage",
        component: () => import("@/views/admin/userManage/UserManage.vue"),
        children: [
            {
                path: "userList",
                name: "userManage/userList",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/userManage/sub/UserList.vue")
            }
        ],
    },
]