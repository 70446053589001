
export default {
    methods: {
        async $getApi(url, payload, header) {
            return await this.$axios.get(url, payload, header).catch(err => {
                if (err.response.status == 401) {
                    alert(JSON.stringify(err.message)+"\n 로그인을 다시 해주시기바랍니다.")
                    this.$store.commit("userLogout");
                    this.$router.push('/login');
                }else if(err.response.status == 400){
                    alert(JSON.stringify(err.message) + "\n 로그인을 다시 해주시기바랍니다.")
                    this.$store.commit("userLogout");
                    this.$router.push('/login');
                }else if(err.response.status == 500){
                    alert(JSON.stringify(err.message) + "\n 에러가 발생하였습니다. 관리자에게 문의해주세요."); 
                }
                else{
                    alert(JSON.stringify(err.message) + "\n 에러가 발생하였습니다. 관리자에게 문의해주세요.")
                    this.$router.push('/');
                }
            });
        },
        async $postApi(url, payload, header) {
            return await this.$axios.post(url, payload, header).catch( err => {
                if(err.response.status == 401 ){
                    alert(JSON.stringify(err.message) + "\n 로그인을 다시 해주시기바랍니다.")
                    this.$store.commit("userLogout");
                    this.$router.push('/login');
                }else if(err.response.status == 400){
                    alert(JSON.stringify(err.message) + "\n 로그인을 다시 해주시기바랍니다.")
                    this.$store.commit("userLogout");
                    this.$router.push('/login');
                }else{
                    alert(JSON.stringify(err.message) + "\n 에러가 발생하였습니다. 관리자에게 문의해주세요.")
                    this.$router.push('/');
                }
            });
        },

        $fileDownload(seq, fileName){
            let URI = '/api/file?seq=' + seq;

            this.$axios.get(URI,{
                responseType : 'blob',
                headers : {
                "Authorization": "Bearer " + this.getCookie("token"),
                }
            }).then(res =>{
                let url = window.URL.createObjectURL(res.data)
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
            })
        },
        async $getFile() {
            let URI = "/api/form/picture?seq=" + this.fileSeq;

            let result = await this.$axios.get(URI, this.getAuthToken()).catch(err => {
                if(err.response.status == 401 ){
                    alert("로그인을 다시 해주시기바랍니다.")
                    this.$store.commit("userLogout");
                    this.$router.push('/login');
                }else if(err.response.status == 400){
                    alert("로그인을 다시 해주시기바랍니다.")
                    this.$store.commit("userLogout");
                    this.$router.push('/login');
                }else{
                    alert("에러가 발생하였습니다. 관리자에게 문의해주세요.")
                    this.$router.push('/');
                }
            })
            
            let fileInfo = result.data.data;
            return {
                fileInfo : fileInfo,
                userDocName : fileInfo.userDoc.originName,
                hireDocName : fileInfo.hireDoc.originName,
                interviewDocName : fileInfo.interviewDoc.originName,
                bankDocName : fileInfo.bankDoc.originName
            }
        },
        
    }
}