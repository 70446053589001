/* 
┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
┃ @소스코드: 정의 명세서                             ┃
┣━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┫
┣ @설명: Vuex
┣ @작성: 신우균, 2023-03-05                     
┣ @내역: 신우균, 2023-03-05, 최초등록
        신우균, 2023-03-05, 네이밍 변경 및
                          vue-persistedstate 제거
┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛
*/
import Vue from 'vue';
import Vuex from 'vuex';
import main from "../main.js"
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        navbarDisplay: false,
        prevPage: {
            currentPage: null,
            scrollY: null,
        },
        loginUser: {
            status: false,
            name: null,
            id: null,
            roleType: null, // ADMIN OR MEMBER
        }
    },
    getters: {
        getPrevPage(state) {
            return state.prevPage;
        },
        userLogin(state) {
            return state.loginUser;
        }
    },
    mutations: {
        userLogin(state, userData) {
            state.loginUser.status = true;
            state.loginUser.name = userData.name;
            state.loginUser.id = userData.uid;
            state.loginUser.roleType = userData.role;
        },
        userLogout(state) {
            main.setCookie("token", "", 0);
            state.loginUser.status = false;
            state.loginUser.name = null;
            state.loginUser.id = null;
            state.loginUser.roleType = null;
        },
    },
    actions: {

    },
    modules: {

    }
})


