/* 
┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
┃ @소스코드: 정의 명세서                             ┃
┣━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┫
┣ @설명: CKEDITOR 공통 컴포넌트
┣ @작성: 유제혁, 2023-01-31                     
┣ @내역: 유제혁, 2023-01-31, 최초등록
┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛
*/
<template>
    <ckeditor
        :editor="editor"
        v-model="propEditorData" 
        :config="editorConfig"
    ></ckeditor>
</template>

<script>

import UploadAdapter from '@/plugins/UploadAdapter.js'
import Editor from 'ckeditor5-custom-build/build/ckeditor';
export default {
    watch: {
        editorData: {
            // Dialog show event handler
            handler() {
                this.propEditorData = this.editorData;
            }
        },
    },
    data() {
        return {
            editor: Editor,
            propEditorData : this.editorData,
            editorConfig : {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'undo',
                        'redo',
                        '|',
                        '-',
                        'fontSize',
                        'fontColor',
                        'fontBackgroundColor',
                        'alignment',
                        'bold',
                        'italic',
                        'highlight',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'outdent',
                        'indent',
                        '|',
                        'imageInsert',
                        'mediaEmbed',
                        'insertTable',
                        'link',
                        '|',
                        'blockQuote',
                        'horizontalLine'
                    ],
                    // items: [
                    //     'heading',
                    //     '|',
                    //     'fontColor',
                    //     'fontBackgroundColor',
                    //     'fontSize',
                    //     'alignment',
                    //     'bold',
                    //     'italic',
                    //     'highlight',
                    //     'link',
                    //     'bulletedList',
                    //     'numberedList',
                    //     '|',
                    //     'outdent',
                    //     'indent',
                    //     '|',
                    //     'imageUpload',
                    //     'blockQuote',
                    //     'insertTable',
                    //     'undo',
                    //     'redo',
                    //     'horizontalLine'
                    // ],
                    shouldNotGroupWhenFull: true,
                },
                language: 'ko',
                image: {
                    toolbar: [
                        'imageTextAlternative',
                        'toggleImageCaption',
                        'imageStyle:inline',
                        'imageStyle:block',
                        'imageStyle:side',
                        'linkImage'
                    ]
                },
                table: {
                    contentToolbar: [
                        'tableColumn',
                        'tableRow',
                        'mergeTableCells',
                        'tableCellProperties',
                        'tableProperties'
                    ]
                },
                extraPlugins: [function(editor) {
                    const _this = editor.sourceElement.__vue__;
                    const token = _this.getAuthToken().headers.Authorization;



                    editor.plugins.get("FileRepository").createUploadAdapter = (
                        loader
                    ) => {
                        
                        const ua = new UploadAdapter(loader, token, _this);

                        return ua;
                    };
                }],


            },
        }
    },
    props : {
        editorData : {
            type : String,
            required : true
        }
    },
    methods : {
        /**********************************************************************
         *  @method     : ckeditor upload Adaptor
         *  @param      { undefined }
         *  @returns    { undefined }
         *********************************************************************/
        uploader(editor) {
            const _this = editor.sourceElement.__vue__;
            const token = _this.getAuthToken().headers.Authorization;



            editor.plugins.get("FileRepository").createUploadAdapter = (
                loader
            ) => {
                
                const ua = new UploadAdapter(loader, token, _this);

                return ua;
            };
        },

        /**********************************************************************
         *  @method     : CkEditor Focus ('제목' 텍스트 필드에서 탭버튼 입력시 포커싱)
         *  @param      { undefined }
         *  @returns    { undefined }
         *********************************************************************/
        eventFocus(){
            const ckEditor = document.getElementsByClassName("ck-content")[0];
            ckEditor.focus();
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-card__text{
        height: 350px;
        
    }
    .editor__wrap {
        height: 350px;
    }
    ::v-deep .ck-editor__editable { min-height: 300px; }

    .ck-content p { margin: 0px; padding: 0px; }
</style>