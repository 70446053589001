/* 
┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
┃ @소스코드: 정의 명세서                             ┃
┣━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┫
┣ @설명: 페이지가드를 통하지 않는 라우터 주소 파일
┣ @작성: 신우균, 2023-03-05                     
┣ @내역: 신우균, 2023-03-05, 최초등록
┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛
*/
export default [
    {
        path: "/business/residentialRent/formReadOnly",
        name: "/business/residentialRent/formReadOnly",
        meta: { auth: "ADMIN" },
        props: { writerMode: false, checkYn: 'Y' }, 
        component: () => import("@/views/user/business/residentialRent/Form.vue")
    },
    // {
    //     path: "/backOffice",
    //     name: "backOffice",
    //     component: () => import("@/views/admin/AdminLogin.vue"),
    // },
    {
        path: "/admin/login",
        name: "adminLogin",
        component: () => import("@/views/admin/AdminLogin.vue"),
    },
    {
        path: "/backOffice",
        redirect: to => {
            if ("expireToken" in to.query) {
                return { path: "/admin/login", query: { expireToken: to.query.expireToken }}
            } else {
                return { path: "/admin/login" }
            }
        }
    },
    // ***** 프린트 출력용 페이지 start *****
    // 면접스타일링 정장대여 신청폼 프린트페이지
    {
        path: "/business/interviewStyling/suitRental/form/viewer",
        name: "/business/interviewStyling/suitRental/form/viewer",
        component: () => import("@/views/user/business/interviewStyling/suitRental/FormViewer.vue")
    },
    // 면접스타일링 정장대여 개인정보 동의서 프린트페이지
    {
        path: "/business/interviewStyling/suitRental/print/privacy",
        name: "/business/interviewStyling/suitRental/print/privacy",
        component: () => import("@/views/user/business/interviewStyling/suitRental/print/PrivacyPrint.vue")
    },
    // 면접스타일링 정장대여 대여 이용약관 프린트페이지
    {
        path: "/business/interviewStyling/suitRental/print/requiredAgreement",
        name: "/business/interviewStyling/suitRental/print/requiredAgreement",
        component: () => import("@/views/user/business/interviewStyling/suitRental/print/RequiredAgreementPrint.vue")
    },
    // 면접스타일링 헤어&증명 신청폼 프린트페이지
    {
        path: "/business/interviewStyling/hairAndPhoto/form/viewer",
        name: "/business/interviewStyling/hairAndPhoto/form/viewer",
        component: () => import("@/views/user/business/interviewStyling/hairAndPhoto/FormViewer.vue")
    },
    // 면접스타일링 헤어&증명 개인정보 처리방침 프린트 페이지
    {
        path: "/business/interviewStyling/hairAndPhoto/print/privacy",
        name: "/business/interviewStyling/hairAndPhoto/print/privacy",
        component: () => import("@/views/user/business/interviewStyling/hairAndPhoto/print/PrivacyPrint.vue")
    },
    // 면접스타일링 헤어&증명 개인(민감)정보 수집 및 이용 · 제공 동의서 프린트 페이지
    {
        path: "/business/interviewStyling/hairAndPhoto/print/privacySensitive",
        name: "/business/interviewStyling/hairAndPhoto/print/privacySensitive",
        component: () => import("@/views/user/business/interviewStyling/hairAndPhoto/print/PrivacySensitivePrint.vue")
    },
    // 면접스타일링 헤어&증명 이용약관 프린트페이지
    {
        path: "/business/interviewStyling/hairAndPhoto/print/requiredAgreement",
        name: "/business/interviewStyling/hairAndPhoto/print/requiredAgreement",
        component: () => import("@/views/user/business/interviewStyling/hairAndPhoto/print/RequiredAgreementPrint.vue")
    },
    // 주거임차보증금 이자지원 신청폼 프린트페이지
    {
        path: "/business/interestSupport/form/viewer",
        name: "/business/interestSupport/form/viewer",
        component: () => import("@/views/user/business/interestSupport/FormViewer.vue")
    },
    // 주거임차보증금 이자지원 개인정보 동의서 프린트 페이지
    {
        path: "/business/interestSupport/print/privacy",
        name: "/business/interestSupport/print/privacy",
        component: () => import("@/views/user/business/interestSupport/print/PrivacyPrint.vue")
    },
    // 주거임차보증금 이자지원 개인정보 동의서(청년) 프린트 페이지
    {
        path: "/business/interestSupport/print/youthPrivacy",
        name: "/business/interestSupport/print/youthPrivacy",
        component: () => import("@/views/user/business/interestSupport/print/YouthPrivacyPrint.vue")
    },
    // 주거임차보증금 이자지원 지원신청자 의무사항 프린트 페이지
    {
        path: "/business/interestSupport/print/obligations",
        name: "/business/interestSupport/print/obligations",
        component: () => import("@/views/user/business/interestSupport/print/ObligationsPrint.vue")
    },
    // 주거임차보증금 이자지원 포기 신청폼 프린트페이지
    {
        path: "/business/interestSupport/failForm/viewer",
        name: "/business/interestSupport/failForm/viewer",
        component: () => import("@/views/user/business/interestSupport/print/FailFormViewer.vue")
    },
    // 주거임대료 변경 신청서 프린트 페이지
    {
        path: "/business/residentialRent/changeForm/viewer",
        name: "/business/residentialRent/changeForm/viewer",
        meta: { adminAuth: true },
        props: { readMode : true }, 
        component: () => import("@/views/user/business/residentialRent/print/ChangeFormPrint.vue")
    },
    // 주거임대료 중지 신청서 프린트 페이지
    {
        path: "/business/residentialRent/stopForm/viewer",
        name: "/business/residentialRent/stopForm/viewer",
        meta: { adminAuth: true },
        props: { readMode : true }, 
        component: () => import("@/views/user/business/residentialRent/print/StopFormPrint.vue")
    },
    // 주거임대료 연장 신청서 프린트 페이지
    {
        path: "/business/interestSupport/extension/viewer",
        name: "/business/interestSupport/extension/viewer",
        component: () => import("@/views/user/business/interestSupport/print/ExtensionFormViewer.vue")
    },
    // 주거임대료 신청폼 프린트페이지
    {
        path: "/business/residentialRent/form/viewer",
        name: "/business/residentialRent/form/viewer",
        component: () => import("@/views/user/business/residentialRent/FormViewer.vue")
    },
    // 주거임대료 이자지원 개인정보 동의서 프린트 페이지
    {
        path: "/business/residentialRent/print/privacy",
        name: "/business/residentialRent/print/privacy",
        component: () => import("@/views/user/business/residentialRent/print/PrivacyPrint.vue")
    },
    // 주거임대료 이자지원 개인정보 동의서(청년) 프린트 페이지
    {
        path: "/business/residentialRent/print/youthPrivacy",
        name: "/business/residentialRent/print/youthPrivacy",
        component: () => import("@/views/user/business/residentialRent/print/YouthPrivacyPrint.vue")
    },
    // 주거임대료 이자지원 지원신청자 의무사항 프린트 페이지
    {
        path: "/business/residentialRent/print/obligations",
        name: "/business/residentialRent/print/obligations",
        component: () => import("@/views/user/business/residentialRent/print/ObligationsPrint.vue")
    },
    /***** 청년 미래적금 *****/
    {
        path: "/business/futureSavings/form/viewer",
        name: "/business/futureSavings/form/viewer",
        component: () => import("@/views/user/business/futureSavings/FormViewer.vue")
    },
    /***** 청년 소상공인 사회보험료 신청폼 프린트 페이지 *****/
    {
        path: "/business/socialInsurance/form/viewer",
        name: "/business/socialInsurance/form/viewer",
        component: () => import("@/views/user/business/socialInsurance/FormViewer.vue")
    },
    /***** 청년 소상공인 사회보험료 지원금 신청자 조회 페이지 (관제용) *****/
    {
        path: "/admin/business/socialInsurance/employee/list",
        name: "/admin/business/socialInsurance/employee/list",
        meta: { auth: "ADMIN" },
        component: () => import("@/views/admin/businessManage/sub/SocialInsuranceEmpList.vue")
    },
    
]