import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import vuetify from "./plugins/vuetify";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import CkEditor from '@/components/common/CkEditor.vue'
import vueMoment from 'vue-moment'
import AlertDialog from "@/components/common/AlertDialog";
import "@babel/polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import mixins from '@/mixins/api'
import CurrentMenuPanel from "@/components/common/CurrentMenuPanel";
import TablePagination from "@/components/common/Pagination.vue";
import DatePicker from "@/components/common/DatePicker.vue";

Vue.use(vueMoment);
Vue.use(CKEditor);
Vue.component("CkEditor", CkEditor);
Vue.component("AlertDialog", AlertDialog);
Vue.component("CurrentMenuPanel", CurrentMenuPanel);
Vue.component("TablePagination", TablePagination);
Vue.component("DatePicker", DatePicker);
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$EventBus = new Vue();

Vue.mixin(mixins);
Vue.mixin({
    filters: {
        commaSeparator(number) {
            if (!number) {
                return "";
            }
            const parts = number.toString().split('.');
            parts[0] = parts[0].replace(/(^0+)/, ""); 
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return parts.join('.');
        }
    },
    methods: {
		comma(obj) {
			var regx = new RegExp(/(-?\d+)(\d{3})/);
			var bExists = obj.indexOf(".", 0);//0번째부터 .을 찾는다.
			var strArr = obj.split('.');
			while (regx.test(strArr[0])) {//문자열에 정규식 특수문자가 포함되어 있는지 체크
				//정수 부분에만 콤마 달기 
				strArr[0] = strArr[0].replace(regx, "$1,$2");//콤마추가하기
			}
			if (bExists > -1) {
				//. 소수점 문자열이 발견되지 않을 경우 -1 반환
				obj = strArr[0] + "." + strArr[1];
			} else { //정수만 있을경우 //소수점 문자열 존재하면 양수 반환 
				obj = strArr[0];
			}
			return obj;//문자열 반환
		},
		//콤마 풀기
		uncomma(str) {
			str = "" + str.replace(/,/gi, ''); // 콤마 제거 
			str = str.replace(/(^\s*)|(\s*$)/g, ""); // trim()공백,문자열 제거 
			return (new Number(str));//문자열을 숫자로 반환
		},
        // Todo 안쓰면 지우세요. - 신우균 -
        // /*------------------------------------------------------------------------------
        // * 함수명 : replace()
        // * 처리내용 : GET 전송시 특수문자함께 전송하는 방법
        // ------------------------------------------------------------------------------*/
        // replaceParam(inum) {
        //     inum = inum.replace(/#/g,"%23");
        //     inum = inum.replace(/&/g,"%26");
        //     inum = inum.replace(/\+/g,"%2B");
        //     return inum;
        // },
        // /*----------------------------------------------------------------------------*/
        
       

        /**********************************************************************
         *  @method		: 토큰을 쿠키에서 로드
         *  @param      { string }      : 포맷 형태
         *  @returns    { object }		: 포맷 형태에 따른 객체
         *********************************************************************/
        getAuthToken(use="headers") {
            const authToken = this.getCookie("token");
            // 저장된 토큰값이 없을때
            if (!authToken) {
                this.$store.commit("userLogout");
                this.$router.push("/login");
                return false
            }
            // 로그인 처리
            // this.$store.commit("adminLogin");

            // Axios 호출 헤더 포맷
            if (use == "headers") {
                const res = {
                    headers: {
                        "Authorization": "Bearer " + authToken,
                    }
                }
                return res;
            }
            // 토근 발급용 포맷
            if (use == "token") {
                const res = {
                    "Authorization": "Bearer " + authToken
                }
                return res;
            }
        },

        /**********************************************************************
         *  @method     : JWT token decode
         *  @param      { string }
         *  @returns    { object }
         *********************************************************************/
        jwtDecode(token) {
            try {
                const base64Url = token.split(".")[1];
                const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
                const result = JSON.parse(jsonPayload);
                return result;
            } catch {
                return false;
            }
        },
            
        /**********************************************************************
         *  @method     : Set cookie
         *  @param      { string }		:name
         *  @param      { string }		:value
         *  @param      { number }		:expire(ms)
         *  @returns    { undefined }
         *********************************************************************/
        setCookie(name, value, exp) {
            const date = new Date();
            date.setTime(date.getTime() + exp*24*60*60*1000);
            document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
        },

        /**********************************************************************
         *  @method     : Get cookie
         *  @param      { string }		:name
         *  @returns    { string }		:value
         *********************************************************************/
        getCookie(name) {
            const value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
            return value? value[2] : null;
        },

        /**********************************************************************
         *  @method		: DateTime 문자열 변환 
         *  @param      { String }
         *  @returns    { String }      :YYYY-MM-DD HH:MM:SS
         *********************************************************************/
        dateFormatting(date) {
            try {
                const res = date.replace("T", " ");
                return res
            } catch (e) {
                return ""
            }
        },
        /**********************************************************************
         *  @method		: DateTime 문자열 변환 
         *  @param      { String }
         *  @returns    { String }      :YYYY-MM-DD HH:MM:SS
         *********************************************************************/
        dateFormattingYear(date) {
            try {
                let res = date.replace("T", " ");
                const year = new Date(res).getFullYear();

                return year
            } catch (e) {
                return ""
            }
        },

        /**********************************************************************
         *  @method     : Axios error handling
         *  @param      { Number }
         *  @returns    { object }
         *********************************************************************/
		axiosErrorHandling(code) {
			switch(code) {
				case 400:
					return { status: "error", code: 400, msg: "잘못된 요청 구문입니다." };
				case 401:
					return this.$router.push("/memberService/login?expireToken=true");
                case 403:
                    return { status: "error", code: 403, msg: "접근 권한이 없습니다."};
				case 404:
					return { status: "error", code: 404, msg: "Not Found" };
				case 500:
					return { status: "error", code: 500, msg: "서버 에러" };
				default:
					return { status: "error", code: code , msg: "unknown" };
			}
        },
        
        /**********************************************************************
         *  @method     : CkEditor Focus ('제목' 텍스트 필드에서 탭버튼 입력시 포커싱)
         *  @param      { undefined }
         *  @returns    { undefined }
         *********************************************************************/
        editorFocus() {
            this.$refs.ckeditor.eventFocus();
        },

        /**********************************************************************
         *  @method     : 신청대상자 청년 나이 검증
         *  @param      { number }      :최대 나이
         *  @param      { string }      :생년 (ex: 19950508)
         *  @returns    { boolean }
         *********************************************************************/
        async ageCheck(birth) {
            // 응답 객체
            const resp = {
                "success": false,
                "errorCode": "",
                "errorMessage": "",
            };
            // 생년월일 유효성 검증
            if (!birth) {
                resp.success = false;
                resp.errorMessage = "유효하지 않은 생년월일 입니다. 생년월일 NULL값 입력";
                return resp;
            }
            if (birth.length !== 8) {
                resp.success = false;
                resp.errorMessage = "유효하지 않은 생년월일 입니다. 생년월일 포맷 오류(YYYYMMDD)";
                return resp;
            }
            const url = "/api/ageSet";
            return await this.$axios.get(url, this.getAuthToken())
            .then(res => {
                const data = res.data.data
                const rrnYear = Number(birth.substring(0,4));
                const dayStart = Number(data.startDate)
                const dayEnd = Number(data.endDate)
                if (rrnYear > dayStart) {
                    resp.success = false;
                    resp.errorMessage = `${ data.startDate }년생 미만은 사업신청 대상자가 아닙니다.`;
                    return resp;
                } 
                if (rrnYear < dayEnd) {
                    resp.success = false;
                    resp.errorMessage = `${ data.endDate }년생 초과는 사업신청 대상자가 아닙니다.`;
                    return resp;
                } 
                resp.success = true;
                return resp;
            })
            .catch(err => {
                if ("response" in err) {
                    const error = this.axiosErrorHandling(err.response.status);
                    resp.success = false;
                    resp.errorMessage = error.msg;
                    resp.errorCode = error.code;
                    return resp;
                } else {
                    resp.success = false;
                    resp.errorMessage = "신청대상자 확인에 실패하였습니다.";
                    return resp;
                }
            })
        },
    }
})
new Vue({
    router,
    store,
    vuetify,
    axios,
    render: (h) => h(App),
}).$mount("#app");

export default {
    setCookie(name, value, exp) {
        const date = new Date();
        date.setTime(date.getTime() + exp*24*60*60*1000);
        document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
    },   
}