export default class UploadAdapter {
    constructor(loader, token, _this) {
        
        this.vue = _this;
        this.loader = loader;
        this.token = token;
        this.fileList = null;
    }

    upload() {
        return this.loader.file.then(file => new Promise((resolve, reject) => {
            this._initRequest();
            this._initListeners(resolve, reject);
            this._sendRequest(file);
        }))
    }

    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();
        xhr.open('POST', '/api/file', true);
        xhr.setRequestHeader('Authorization', this.token);
        xhr.responseType = 'json';
    }

    _initListeners(resolve, reject) {
        
        const xhr = this.xhr;
        const genericErrorText = '파일을 업로드 할 수 없습니다.'

        xhr.addEventListener('error', () => { reject(genericErrorText) })
        xhr.addEventListener('abort', () => reject())
        xhr.addEventListener('load', () => {
            const response = xhr.response
            


            if (response.code == 2001) {
                this.vue.$store.commit("userLogout");
                this.vue.$router.push('/backoffice');
                return reject(response.message);
            }
            

            resolve({
                default: response.data.path //업로드된 파일 주소
            })


        })
    }

    _sendRequest(file) {
        const data = new FormData()
        data.append('file', file);
        data.append('is_public', 1);
        this.xhr.send(data);
    }
}