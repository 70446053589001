<template>
    <v-app id="app">
        <router-view/>
    </v-app>
</template>

<script>

export default {
    name: "App",
    data() {
        return {

        }
    },
};
</script>

<style lang="scss">
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
@import "@/scss/reset.scss";

#app {
  font-family: "Noto Sans KR", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body { -webkit-print-color-adjust: exact; }

@font-face{
  font-family: 'JalnanOTF';
  src : url('./assets/font/JalnanOTF.otf');
}
@font-face {
    font-family: 'LINESeedKR-Bd';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/LINESeedKR-Bd.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
</style>
