/* 
┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
┃ @소스코드: 정의 명세서                             ┃
┣━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┫
┣ @설명: DatePicker
┣ @작성: 신우균, 2022-05-24                        
┣ @내역: 신우균, 2022-05-24, 최초등록
        신우균, 2023-05-15, 기능 고도화
┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛
*/
<template>
    <v-menu
        v-model="datePicker"
        :close-on-content-click="false"
        max-width="290"
        nudge-bottom="40"
        :disabled="config.readOnly"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                ref="form"
                :value="selectedDate"
                v-bind="attrs"
                v-on="on"
                :rules="config.required ? [rules.required] : null"
                :clearable="!config.readOnly && config.clearable"
                :placeholder="config.placeholder"
                readonly
                outlined
                dense
                hide-details="auto"
                
                @click:clear="dateReset()"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="selectedDate"
            @input="datePicker = false"
            @change="dateChange"
        ></v-date-picker>
    </v-menu>
</template>

<script>
export default {
    props: {
        config: {
            default: () => {
                return {
                    // 읽기 모드
                    readOnly: false,
                    // 유효성(필수 선택) 모드
                    required: true,
                    // 입력폼 기본 표시값
                    placeholder: "날짜를 선택해주세요.",
                    // 삭제 버튼 활성화 여부
                    clearable: true,
                }
            }
        },
        date: {
            default: "",
        }
    },
    data() {
        return {
            datePicker: false,
            // propsDate: "",
            selectedDate: "",
            /*****************************************************************
             *  Form validate
             ****************************************************************/
            rules: {
                required: value => !!value || "필수 입력 항목입니다.",
            }
        }
    },
    watch: {
        selectedDate() {
            if (!this.date) {
                return;
            }
            this.selectedDate = this.date;
        },
    },
    created() {
        this.selectedDate = this.date;
    },
    methods: {
        /**********************************************************************
         *  @method     : 날짜 변경 핸들러
         *  @param      { undefined }
         *  @returns    { undefined }
         *********************************************************************/
        dateChange() {
            this.$emit("change", this.selectedDate);
        },

        /**********************************************************************
         *  @method     : 날짜 초기화 핸들러
         *  @param      { undefined }
         *  @returns    { undefined }
         *********************************************************************/
        dateReset() {
            this.selectedDate = null;
            this.$emit("change", this.selectedDate);
        },
    }
}
</script>

<style>

</style>