export default [
    {
        path: "webManage",
        name: "webManage",
        component: () => import("@/views/admin/webManage/WebManage.vue"),
        children: [
            {
                path: "notice",
                name: "webManage/notice",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/webManage/sub/Notice.vue")
            },
            {
                path: "archieve",
                name: "webManage/referenceRoom",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/webManage/sub/Archieve.vue")
            },
            {
                path: "mainSlide",
                name: "webManage/mainSlide",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/webManage/sub/MainSlide.vue")
            },
            {
                path: "video",
                name: "webManage/video",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/webManage/sub/Video.vue")
            },
            {
                path: "taskManager",
                name: "webManage/taskManager",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/webManage/sub/TaskManager.vue")
            },
            {
                path: "popupManage",
                name: "webManage/popup",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/webManage/sub/PopupManage.vue")
            },
            {
                path: "newsClipping",
                name: "webManage/newsClipping",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/webManage/sub/NewsClipping.vue")
            },
            {
                path: "businessManagement",
                name: "webManage/businessManagement",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/webManage/sub/BusinessManagement.vue")
            }
        ],
      },
]