export default [
    /**************************************************************************
     *  면접스타일링
     *************************************************************************/
    // 면접스타일링 정장대여 신청폼 페이지
    {
        path: "business/interviewStyling/suitRental/form",
        name: "business/interviewStyling/suitRental/form",
        meta: { auth: "MEMBER" },
        component: () => import("@/views/user/business/interviewStyling/suitRental/Form.vue")
    },
    // 면접스타일링 헤어&증명 신청폼 페이지
    {
        path: "business/interviewStyling/hairAndPhoto/form",
        name: "business/interviewStyling/hairAndPhoto/form",
        meta: { auth: "MEMBER" },
        component: () => import("@/views/user/business/interviewStyling/hairAndPhoto/Form.vue")
    },

    /**************************************************************************
     *  청년 미래적금
     *************************************************************************/
    {
        path: "business/futureSavings/form",
        name: "business/futureSavings/form",
        meta: { auth: "MEMBER" },
        component: () => import("@/views/user/business/futureSavings/Form.vue")
    },
    
    /**************************************************************************
     *  주거임대료
     *************************************************************************/
    // 주거임대료 사업소개 페이지
    {
        path: "business/residentialRent",
        name: "business/residentialRent",
        component: () => import("@/views/user/business/residentialRent/Introduce.vue")
    },
    // 주거임대료 지원대상자 자가진단 페이지
    {
        path: "business/residentialRent/selfCheck",
        name: "business/residentialRent/selfCheck",
        meta: { auth: "MEMBER" },
        component: () => import("@/views/user/business/residentialRent/SelfCheck.vue")
    },

    // Todo 
    // To. 유제혁, 아래 두개 라우터 확인후 삭제 요망 - 신우균-
    // 주거임대료 신청폼 페이지
    {
        path: "business/residentialRent/form",
        name: "business/residentialRent/form",
        meta: { auth: "MEMBER" },
        props: true, 
        component: () => import("@/views/user/business/residentialRent/Form.vue")
    },

    // 주거임대료 신청폼 페이지 자가진단 체크무시
    {
        path: "business/residentialRent/selfCheck/form",
        name: "business/residentialRent/selfCheck/form",
        meta: { auth: "MEMBER" },
        props: {checkYn : "true"}, 
        component: () => import("@/views/user/business/residentialRent/Form.vue")
    },


    // 주거임대료 신청완료 페이지
    {
        path: "business/residentialRent/formResult",
        name: "business/residentialRent/formResult",
        component: () => import("@/views/user/business/residentialRent/FormResult.vue")
    },
    // 주거임대료 중지 페이지
    {
        path: "business/residentialRent/stopForm",
        name: "business/residentialRent/stopForm",
        meta: { auth: "MEMBER" },
        component: () => import("@/views/user/business/residentialRent/StopForm.vue")
    },
    // 주거임대료 변경 페이지
    {
        path: "business/residentialRent/changeForm",
        name: "business/residentialRent/changeForm",
        meta: { auth: "MEMBER" },
        component: () => import("@/views/user/business/residentialRent/ChangeForm.vue")
    },

    /**************************************************************************
     *  주거임차보증금
     *************************************************************************/
    // 주거임차보증금 이자지원 사업소개 페이지
    {
        path: "business/interestSupport",
        name: "business/interestSupport",
        component: () => import("@/views/user/business/interestSupport/Introduce.vue")
    },
    // 주거임차보증금 이자지원 자가진단 페이지
    {
        path: "business/interestSupport/selfCheck",
        name: "business/interestSupport/selfCheck",
        meta: { auth: "MEMBER" },
        component: () => import("@/views/user/business/interestSupport/SelfCheck.vue")
    },
    // 주거임차보증금 이자지원 신청폼 페이지
    {
        path: "business/interestSupport/form",
        name: "business/interestSupport/form",
        meta: { auth: "MEMBER" },
        component: () => import("@/views/user/business/interestSupport/Form.vue")
    },
    // 주택임차보증금 이자지원 포기 페이지
    {
        path: "business/interestSupport/failForm",
        name: "business/interestSupport/failForm",
        meta: { auth: "MEMBER" },
        component: () => import("@/views/user/business/interestSupport/FailForm.vue")
    },
    /***** 주택임차보증금 이자지원 연장 *****/
    {
        path: "business/interestSupport/extension",
        name: "business/interestSupport/extension",
        meta: { auth: "MEMBER" },
        component: () => import("@/views/user/business/interestSupport/ExtensionForm.vue")
    },

    /**************************************************************************
     *  사회보험료
     *************************************************************************/
    // 사회보험료 자가진단 페이지
    {
        path: "business/socialInsurance/selfCheck",
        name: "business/socialInsurance/selfCheck",
        component: () => import("@/views/user/business/socialInsurance/SelfCheck.vue")
    },
    // 사회보험료 신청폼
    {
        path: "business/socialInsurance/form",
        name: "business/socialInsurance/form",
        component: () => import("@/views/user/business/socialInsurance/Form.vue")
    },
]

