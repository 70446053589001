export default [
    {
        path: "memberService/login",
        name: "userLogin",
        component: () => import("@/views/user/memberService/Login.vue")
    },
    {
        path: "memberService/lostAccount",
        name: "userLostAccount",
        component: () => import("@/views/user/memberService/LostAccount.vue")
    },
    {
        path: "memberService/join",
        name: "userJoin",
        component: () => import("@/views/user/memberService/join/PolicyHome.vue")
    },
    {
        path: "memberService/join/cert",
        name: "userJoinCertification",
        component: () => import("@/views/user/memberService/join/UserCertification.vue")
    },
    {
        path: "memberService/join/form",
        name: "userJoinCert",
        component: () => import("@/views/user/memberService/join/JoinForm.vue")
    },
    // ***** 마이페이지 start *****
    
    {
        path: "memberService/myPage",
        component: () => import("@/views/user/memberService/myPage/MyPageGuard.vue"),
        children: [
            {
                path: "userModify/pwdCheck",
                name: "userPasswordCheck",
                meta: { auth: "MEMBER" },
                component: () => import("@/views/user/memberService/myPage/UserPwdCheck.vue")
            },
            {
                path: "userModify",
                name: "userModfiy",
                meta: { auth: "MEMBER" },
                component: () => import("@/views/user/memberService/myPage/UserModify.vue")
            },
            {
                path: "userPwdChange",
                name: "userPwdChange",
                meta: { auth: "MEMBER" },
                component: () => import("@/views/user/memberService/myPage/UserPwdChange.vue")
            },
            {
                path: "userBusiness",
                name: "userBusiness",
                meta: { auth: "MEMBER" },
                component: () => import("@/views/user/memberService/myPage/UserBusiness.vue")
            },
            {
                path: "userLeave",
                name: "userLeave",
                meta: { auth: "MEMBER" },
                component: () => import("@/views/user/memberService/myPage/UserLeave.vue")
            }
        ]
    }
]