export default [
    {
        path: "introduce/support",
        name: "Introduce/support",
        component: () => import("@/views/user/introduce/Support.vue")
    },
    {
        path: "introduce/organization",
        name: "Introduce/organization",
        component: () => import("@/views/user/introduce/Organization.vue")
    },
    {
        path: "introduce/come",
        name: "Introduce/come",
        component: () => import("@/views/user/introduce/Come.vue")
    },
    {
        path: "board",
        name: "board",
        component: () => import("@/views/user/board/Board.vue")
    },
    {
        path: "board/detail",
        name: "board/detial",
        component: () => import("@/views/user/board/BoardDetail.vue")
    },
    {
        path: "board/business",
        name: "board/business",
        component: () => import("@/views/user/board/BoardBusiness.vue")
    },
    {
        path: "board/business/detail",
        name: "board/business/detial",
        component: () => import("@/views/user/board/BoardBusinessDetail.vue")
    },
    {
        path: "board/archive/newsClipping",
        name: "board/archive/newsClipping",
        component: () => import("@/views/user/board/NewsClipping.vue"),
    },
    {
        path: "board/community/faq",
        name: "board/community/faq",
        component: () => import("@/views/user/board/BoardFaq.vue"),
    },
]