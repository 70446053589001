export default [
    {
        path: "businessManage",
        name: "businessManage",
        meta: { adminAuth: false },
        component: () => import("@/views/admin/businessManage/BusinessManage.vue"),
        children: [
            {
                path: "liveHireDeposit",
                name: "businessManage/liveHireDeposit",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/businessManage/sub/LiveHireDepositList.vue"),
            },
            {
                path: "savingsList",
                name: "businessManage/savingsList",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/businessManage/sub/SavingsList.vue"),
            },
            {
                path: "suitRental",
                name: "businessManage/suitRental",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/businessManage/sub/SuitRental.vue"),
            },
            {
                path: "hairAndPhoto",
                name: "businessManage/hairAndPhoto",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/businessManage/sub/HairAndPhoto.vue"),
            },
            {
                path: "faq",
                name: "businessManage/faq",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/businessManage/sub/Faq.vue"),
            },
            {
                path: "houseRentalList",
                name: "houseRentalList",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/businessManage/sub/HouseRentalList.vue"),
            },
            // 주택임차보증금(이자지원)
            {
                path: "interestSupport",
                name: "interestSupport",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/businessManage/sub/InterestSupport.vue"),
            },
            // 청년나이 설정
            {
                path: "setAge",
                name: "setAge",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/businessManage/sub/SetAge.vue"),
            },
            // 사업공고 설정
            {
                path: "businessAnnounce",
                name: "businessAnnounce",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/businessManage/sub/BusinessAnnounce.vue"),
            },
            // 청년 미래적금
            {
                path: "newSavings",
                name: "newSavings",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/businessManage/sub/NewSavings.vue")
            },
            // 소상공인 사회보험료
            {
                path: "socialInsurance",
                name: "socialInsurance",
                meta: { auth: "ADMIN" },
                component: () => import("@/views/admin/businessManage/sub/SocialInsurance.vue")
            }
        ],
    },
]