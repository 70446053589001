/* nav__img
┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
┃ @소스코드: 정의 명세서                             ┃
┣━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┫
┣ @설명: 상단 네비게이션 메뉴 컴포넌트
┣ @작성: 신우균, 2023-01-30                     
┣ @내역: 신우균, 2023-01-30, 최초등록
┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛
*/
<template>
    <div class="top-nav">
        <div class="top-nav__background">
            <h1 class="top-nav__title">{{ currentSubMenuName }}</h1>
            <img
                class="top-nav__img" 
                :src="currentSubMenuImg" 
                alt=""
            >
            <!-- <div class="top-nav__menu">
                <v-btn class="home-button" icon @click="$router.push('/')">
                    <v-icon color="#FFF" large>mdi-home</v-icon>
                </v-btn>
                <div class="top-dropdown">
                    <div class="top-dropdown__item" @mouseleave="dropClose(0)">
                        <div class="top-dropdown__menu" @click="dropClick(0)">
                            {{ currentMenuName }}
                            <v-icon class="arrow" color="#FFF">mdi-chevron-down</v-icon>
                        </div>
                        <div class="top-dropdown__sub" isOpen="close">
                            <div
                                v-for="(item, index) in menuItems"
                                :key="index"
                                class="top-dropdown__sub-item"
                                @click="mainMenuClick(item)"
                            >
                                {{ item.text }}
                            </div>
                        </div>
                    </div>
                    <div class="top-dropdown__item" @mouseleave="dropClose(1)">
                        <div class="top-dropdown__menu" @click="dropClick(1)">
                            {{ currentSubMenuName }}
                            <v-icon class="arrow" color="#FFF">mdi-chevron-down</v-icon>
                        </div>
                        <div class="top-dropdown__sub" isOpen="close">
                            <router-link 
                                v-for="(item, index) in subMenuItemsFilter"
                                :key="index"
                                class="top-dropdown__sub-item" 
                                :to="getSubMenuLink(item.moveLink)"
                            >
                                {{ item.text }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    props: {
        mode: {
            type: String,
            default: "route"
        },
        propsParams: {
            mainPath: "",
            subPath: "",
        },
    },
    data() {
        return {
            menuItems: [
                { text: "비전 및 목표", link: "board/vision", img:require("@/assets/imgs/business-top-panel-bg.jpg") },
                { text: "지원사업", link: "board/support", img:require("@/assets/imgs/business-top-panel-bg.jpg") },
                { text: "조직도", link: "board/organization", img:require("@/assets/imgs/business-top-panel-bg.jpg") },
                { text: "오시는 길", link: "board/come", img:require("@/assets/imgs/business-top-panel-bg.jpg") },
                { text: "사업공고", link: "board/business", img:require("@/assets/imgs/business-top-panel-bg.jpg"), },
                { text: "알림마당", link: "board/community", img:require("@/assets/imgs/business-top-panel-bg.jpg"), },
                { text: "자료실", link: "board/archive", img:require("@/assets/imgs/business-top-panel-bg.jpg") },
                { text: "회원서비스", link: "memberService", img:require("@/assets/imgs/business-top-panel-bg.jpg") },
            ],
            subMenuItems: {
                "board/vision": [
                    { 
                        text: "비전 및 목표", 
                        subPath: "vision", 
                        moveLink: "/introduce/vision" 
                    },
                ],
                "board/support": [
                    { 
                        text: "지원사업", 
                        subPath: "support", 
                        moveLink: "/introduce/support" 
                    },
                ],
                "board/organization": [
                    { 
                        text: "조직도", 
                        subPath: "organization", 
                        moveLink: "/introduce/organization" 
                    },
                ],
                "board/come": [
                    { 
                        text: "오시는 길", 
                        subPath: "come", 
                        moveLink: "/introduce/come" 
                    },
                ],
                memberService: [
                    { 
                        text: "로그인", 
                        subPath: "login", 
                        moveLink: "/memberService/login" 
                    },
                    { 
                        text: "회원가입", 
                        subPath: "join", 
                        moveLink: "/memberService/join" 
                    },
                    { 
                        text: "아이디/비밀번호 찾기", 
                        subPath: "lostAccount", 
                        moveLink: "/memberService/lostAccount" 
                    },
                ],
                
                "board/business": [
                    { 
                        text: "사업공고", 
                        subPath: "", 
                        moveLink: "",
                        // img:require("@/assets/imgs/business-top-panel-bg.jpg"),
                    },
                ],

                "board/introduce": [
                    { 
                        text: "센터소개", 
                        subPath: "", 
                        moveLink: "",
                        img:require("@/assets/imgs/business-top-panel-bg.jpg"),
                    },
                ],
                "board/community": [
                    { 
                        text: "공지사항", 
                        subPath: "notice", 
                        moveLink: "/board?mainPath=community&subPath=notice" 
                    },
                    { 
                        text: "자주 묻는 질문", 
                        subPath: "faq", 
                        moveLink: "/board/community/faq" 
                    },
                ],
                "board/archive": [
                    { 
                        text: "서식/매뉴얼", 
                        subPath: "manual",
                        moveLink: "/board?mainPath=archive&subPath=manual"
                    },
                    { 
                        text: "홍보자료", 
                        subPath: "pr",
                        moveLink: "/board?mainPath=archive&subPath=pr"
                    },
                    { 
                        text: "뉴스클리핑", 
                        subPath: "newsClipping",
                        moveLink: "/board/archive/newsClipping"
                    },
                ]
            },
            currentMenuName: "",
            currentMenuPath: "",
            currentSubMenuName: "",
            currentSubMenuImg: "",
        }
    },
    // computed: {
    //     subMenuItemsFilter() {
    //         return this.subMenuItems[this.currentMenuPath];
    //     }
    // },
    created() {
        this.init();
    },
    methods: {
        /**********************************************************************
         *  @method     : 초기 설정
         *  @param      { undefined }
         *  @returns    { undefined }
         *********************************************************************/
        init() {
            let mainMenuName = "";
            let boardSearchMode = false;
            const routePath = this.$route.path.substring(1).split("/");
            // 수동 검색 모드일 경우
            if (this.mode === "static") {
                mainMenuName = "board/" + this.propsParams.mainPath;
            } 
            // 라우터 검색 모드일 경우
            else {
                // 라우터 패스가 없을 경우 -> 에러처리
                if (routePath.length <= 0) {
                    return -1;
                }
                // 게시판일 경우
                if (routePath[0] === "board") {
                    mainMenuName = routePath[0] + "/" + routePath[1];
                    boardSearchMode = true;
                } else {
                    mainMenuName = routePath[0];
                }
            }

            // 대메뉴 이름 저장
            this.currentMenuPath = mainMenuName;
            // 라우터 주소를 참고하여, 대메뉴 이름을 검색
            for (let i = 0; i < this.menuItems.length; i++) {
                if (this.menuItems[i].link === mainMenuName) {
                    this.currentMenuName = this.menuItems[i].text;
                    this.currentSubMenuImg = this.menuItems[i].img;
                    break;
                }
            }

            if (this.mode === "route") {
                // console.log("1");
                // 라우터 주소를 참고하여, 하위메뉴 이름을 검색
                // if (routePath[0] === "introduce"){
                //     console.log("test")
                //     this.currentSubMenuName = '센터소개';
                //     return -1;
                // }
                if (routePath.length < 2) {
                    return -1;
                }
            }
            
            const subMenu = this.subMenuItems[mainMenuName];

            for (let i = 0; i < subMenu.length; i++) {
                // 수동 검색 모드일 경우
                if (this.mode === "static") {
                    if (subMenu[i].subPath === this.propsParams.subPath) {
                        this.currentSubMenuName = subMenu[i].text;
                    }
                }
                // 라우터 검색 모드일 경우
                if (boardSearchMode) {
                    if (subMenu[i].subPath === routePath[2]) {
                        this.currentSubMenuName = subMenu[i].text;
                        break;
                    }
                }
                if (subMenu[i].subPath === routePath[1]) {
                    this.currentSubMenuName = subMenu[i].text;
                    break;
                }
            }
        },

        /**********************************************************************
         *  @method     : 드롭다운 클릭 이벤트 핸들러
         *  @param      { number }      :element order number
         *  @returns    { undefined }
         *********************************************************************/
        dropClick(idx) {
            const dropdown = document.getElementsByClassName("top-dropdown__item")[idx];
            const dropdownSub = dropdown.getElementsByClassName("top-dropdown__sub")[0];
            const dropdownSubLen = dropdownSub.childElementCount;
            const dropdownSubItem = dropdownSub.getElementsByClassName("top-dropdown__sub-item");
            const dropdownLabel = dropdown.getElementsByClassName("top-dropdown__menu")[0];
            const dropdownArrow = dropdownLabel.getElementsByClassName("arrow")[0];
            
            // 드롭다운 하위메뉴가 닫혀 있을 때
            if (dropdownSub.getAttribute("isOpen") == "close") {
                let subItemHeight = 0;
                for (let i = 0; i < dropdownSubLen; i++) {
                    subItemHeight += dropdownSubItem[i].offsetHeight;
                }
                dropdownSub.style.height = subItemHeight + "px";
                dropdownSub.setAttribute("isOpen", "open");
                dropdownArrow.style.transform = "rotate(180deg)";
            } 
            // 드롭다운 하위메뉴가 열려 있을 때
            else {
                dropdownSub.style.height = "0px";
                dropdownSub.setAttribute("isOpen", "close");
                dropdownArrow.style.transform = "rotate(0deg)";
            }
        },

        /**********************************************************************
         *  @method     : 드롭다운 닫기
         *  @param      { number }      :element order number
         *  @returns    { undefined }
         *********************************************************************/
        dropClose(idx) {
            const dropdown = document.getElementsByClassName("top-dropdown__item")[idx];
            const dropdownSub = dropdown.getElementsByClassName("top-dropdown__sub")[0];
            const dropdownLabel = dropdown.getElementsByClassName("top-dropdown__menu")[0];
            const dropdownArrow = dropdownLabel.getElementsByClassName("arrow")[0];
            dropdownSub.style.height = "0px";
            dropdownArrow.style.transform = "rotate(0deg)";
            dropdownSub.setAttribute("isOpen", "close");
        },

         /**********************************************************************
         *  @method     : 서브 메뉴 라우터 링크 반환 
         *  @param      { string }      :subMenu router path 
         *  @returns    { string }      :full router path
         *********************************************************************/
        getSubMenuLink(link) {
            return link
        },

        mainMenuClick(item) {
            this.currentMenuName = item.text;
            this.currentMenuPath = item.link;
            // this.currentSubMenuName = "";
            this.dropClose(0);
        }
    }
}
</script>
<style lang="scss" scoped>
    @mixin separator($left: none, $right: none) {
        content: "";
        position: absolute;
        width: 1px;
        height: 18px;
        top: 50%;
        left: $left;
        right: $right;
        transform: translateY(calc(-50% + 1.5px));
        background: #9F9F9F;
    }
    @include media(mobile, tablet) {
        .top-nav {
            --height: 220px;
            --menu-width: 100%;
            --menu-height: 70px;
            --dropitem-width: 100%;
        }
        .top-nav__img {
            min-width: 320px !important;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        .top-nav__title {
            margin-top: 30px;
        }
        .top-dropdown { 
            flex: 1; 
            margin-left: 0px !important;
        }
        .home-button { display: none; }
    }
    @include media(desktop) {
        .top-nav {
            --height: 370px;
            --menu-width: 1400px;
            --menu-height: 70px;
            --dropitem-width: 230px;
            &__menu {
                margin: 0 auto;
            }
        }
    }
    .top-nav {
        position: relative;
        z-index: 10;
        &__background {
            width: 100%;
            max-width: 1920px;
            height: var(--height);
            text-align: center;
            margin: 0 auto;
            /* &::before {
                content: "";
                background-size: cover;
                background-image: url("@/assets/imgs/business-top-panel-bg.jpg");
                background-position: center center;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
            } */
        }
        &__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-position: center center;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            
        }
        &__title {
            position: relative;
            display: inline-block;
            max-width: 90%;
            font-size: 36px;
            top: 50%;
            transform: translateY(calc(-50% - 35px));
            z-index: 2;
            // overflow:hidden;
            // text-overflow:ellipsis;
            // white-space:nowrap;
            &::before {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0px;
                width: 100%;
                height: 16px;
                background: #FFD500;
                z-index: -1;
            }
        }
        &__menu {
            position: absolute;
            bottom: 0px;
            right: 50%;
            z-index: 2;
            transform: translateX(50%);
            display: flex;
            align-items: center;
            width: 100%;
            max-width: var(--menu-width);
            height: var(--menu-height);
            background-color: rgba(0, 0, 0, 0.8);
            padding: 0 20px;
            word-break: keep-all;
        }
    }
    .top-dropdown {
        display: flex;
        margin-left: 20px;
        &__item { 
            position: relative;
            width: var(--dropitem-width);
            max-width: 230px;
            &::before { @include separator($left: 0px); }
            &::after { @include separator($right: -1px); }
        }
        &__menu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #FFF;
            font-size: 18px;
            height: 70px;
            padding: 0 10px 0 20px;
            cursor: pointer;
            user-select: none;
        }
        &__sub {
            position: absolute;
            top: 70px;
            width: inherit;
            height: 0px;
            overflow: hidden;
            transition: all ease 0.5s 0s;
        }
        &__sub-item {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            // min-height: 50px;
            // justify-content: flex-start;
            line-height: 1.5;
            padding: 10px 10px 10px 20px;
            background: #FFF;
            color: #000;
            border-left: 1px solid rgba(0, 0, 0, 0.8);
            border-right: 1px solid rgba(0, 0, 0, 0.8);
            word-break: keep-all;
            text-align: left;
            &:last-child {
                border-bottom: 1px solid rgba(0, 0, 0, 0.8);
            }
        }
    }
    @media (max-width: 350px) {
        .top-dropdown__menu { font-size: 15px; }
    }
</style>